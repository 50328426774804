import { type InvalidSubmissionContext, configure } from 'vee-validate'
import { setLocale } from 'yup'
import type { List } from '~/types/custom'

export const useLocalizedValidations = () => {
  const { t } = useI18n()
  setLocale({
    mixed: {
      required: (e: { path: string }) => {
        const path = e?.path || null
        const field = path ? t(`yup.fields.${path}`) : null
        return field ? t('yup.errors.field-required', { field }) : t('yup.errors.required')
      },
    },
    string: {
      min: (e: { path: string; min: number }) => {
        const path = e?.path || null
        const field = path ? t(`yup.fields.${path}`) : null
        return field ? t('yup.errors.field-min', { field, n: e?.min }) : t('yup.errors.min', { n: e?.min })
      },
      email: (e: { path: string }) => {
        const path = e?.path || null
        const field = path ? t(`yup.fields.${path}`) : null
        return field ? t('yup.errors.field-email', { field }) : t('yup.errors.email')
      },
    },
  })
}

export const useValidationHooks = () => {
  configure({
    validateOnBlur: true,
    validateOnChange: true,
    validateOnInput: false,
    validateOnModelUpdate: true,
  })
}

export const useFormRoles = (): List => {
  const { t } = useI18n()

  return [
    {
      id: 1,
      textContent: t('home.beta.form.role.options.0'),
    },
    {
      id: 2,
      textContent: t('home.beta.form.role.options.1'),
    },
    {
      id: 3,
      textContent: t('home.beta.form.role.options.2'),
    },
    {
      id: 4,
      textContent: t('home.beta.form.role.options.3'),
    },
    {
      id: 5,
      textContent: t('home.beta.form.role.options.4'),
    },
    {
      id: 6,
      textContent: t('home.beta.form.role.options.5'),
    },
  ]
}

export const useInvalidSubmit = ({ values, errors, results }: InvalidSubmissionContext) => {
  console.warn('** VEEVALIDATE INVALID SUBMIT **')
  console.warn(errors)
  console.warn(results)
  console.warn(values)
}
